<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Voice Label") }}</div>
    <div class="details">{{ contentFormInfo.tts_label }}</div>
    <div class="titles">{{ __("Voice Name") }}</div>
    <div class="details">{{ contentFormInfo.voice_name }}</div>
    <div class="titles">{{ __("Provider") }}</div>
    <div class="details">{{ contentFormInfo.provider }}</div>
    <div class="titles">{{ __("Language") }}</div>
    <div class="details">{{ contentFormInfo.lang }}</div>
    <div class="titles">{{ __("Gender") }}</div>
    <div class="details">{{ contentFormInfo.gender }}</div>
    <div class="titles">{{ __("System Voice") }}</div>
    <div class="details">{{ contentFormInfo.is_system_voice }}</div>
    <div class="titles">{{ __("Sample Text") }}</div>
    <div class="details" style="height: 60px">
      <el-input
        v-model="contentForm.sample_text"
        type="textarea"
        :placeholder="contentFormInfo.sample_text"
        @input="updatePromptText($event)"
        style="z-index: 1;"
      />
    </div>
    <div class="details">
      <audio-player
        class="audio-player"
        :disabled="!promptText"
        @get-preview="generateAudio"
        :show-reload="promptContentChanged"
        :generating-audio="generatingAudio"
        :file="promptAudioFile"
      />
    </div>
    <el-button @click="handleEdit" class="editBtn" v-show="this.canWrite()">{{
      __("Edit")
    }}</el-button>
    <el-button
      @click="handleDelete(contentFormInfo)"
      style="margin-top: 10px"
      class="deleteBtn"
      v-show="this.canWrite()"
      >{{ __("Delete") }}
    </el-button>
    <el-dialog
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isLoading"
    >
      <el-scrollbar :native="false" style="margin-top: 34px">
        <div style="max-height: calc(100vh - 34px);">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="margin-top: 34px">
              <el-col :span="12" :offset="6">
                <page-header
                  style="padding-left: 0"
                  :title="__('TTS Voice')"
                  :contentId="id"
                />
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="voice_name" :label="__('Name')">
                  <el-input
                    :disabled="
                      this.formAction === 'edit' &&
                        contentForm.provider !== 'lumenvox'
                    "
                    v-model="contentForm.voice_name"
                    v-loading="isCheckingName"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="provider" :label="__('Provider')">
                  <el-select
                    style="width: 100%"
                    :disabled="isDisabledForEdit && isDisabled"
                    v-model="contentForm.provider"
                    :placeholder="__('Select Provider')"
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="provider in providers"
                      :key="provider.label"
                      :label="provider.label"
                      :value="provider.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="lang" :label="__('Language')">
                  <el-select
                    filterable
                    style="width: 100%"
                    :disabled="isDisabledForEdit && isDisabled"
                    v-model="contentForm.lang"
                    :placeholder="__('Select Language')"
                    default-first-option
                  >
                    <el-option
                      v-for="language in languages"
                      :key="language.label"
                      :label="language.label"
                      :value="language.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="gender" :label="__('Gender')">
                  <el-radio-group
                    :disabled="isDisabledForEdit && isDisabled"
                    v-model="contentForm.gender"
                  >
                    <el-radio label="M">{{ __("Male") }}</el-radio>
                    <el-radio label="F">{{ __("Female") }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="tts_label" :label="__('Label')">
                  <el-input
                    v-model="contentForm.tts_label"
                    v-loading="isCheckingLabel"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="is_system_voice"
                  :label="__('System Voice')"
                >
                  <el-switch
                    :disabled="isDisabledForEdit && isDisabled"
                    v-model="contentForm.is_system_voice"
                  ></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              type="primary"
              @click="submitForm"
              class="submitBtn"
              v-show="this.canWrite()"
              :primaryKey="id"
              variant="CreateUpdate"
            />
            <el-button @click="handleCancel" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";

import { getAudioPrompt } from "@/api/services";
import AudioPlayer from "@/components/AudioPlayer";
import { checkUniqueName } from "@/api/ttsVoices";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton,
    AudioPlayer
  },
  props: {
    ttsVoiceToEdit: {
      type: Object,
      required: false,
      default: () => ({
        is_system_voice: false
      })
    },
    id: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  data() {
    let validateVoiceName = async (rule, value, callback) => {
      try {
        // update scenario
        if (this.id === null && value !== this.contentFormInfo.voice_name) {
          this.isCheckingName = true;
          const res = await checkUniqueName({ voice_name: value });
          this.isCheckingName = false;
          if (res.data.found) {
            callback(new Error(__("Voice name already exists")));
          } else {
            callback();
          }
        }
        if (this.id === -1) {
          this.isCheckingName = true;
          const res = await checkUniqueName({ voice_name: value });
          this.isCheckingName = false;
          if (res.data.found) {
            callback(new Error(__("Voice name already exists")));
          } else {
            callback();
          }
        }
      } catch (e) {
        console.log(e);
        this.isCheckingName = false;
      }
    };
    let validateVoiceLabel = async (rule, value, callback) => {
      try {
        // update scenario
        if (this.id === null && value !== this.contentFormInfo.tts_label) {
          this.isCheckingLabel = true;
          const res = await checkUniqueName({ tts_label: value });
          this.isCheckingLabel = false;
          if (res.data.found) {
            callback(new Error(__("Voice label already exists")));
          } else {
            callback();
          }
        }
        if (this.id === -1) {
          this.isCheckingLabel = true;
          const res = await checkUniqueName({ tts_label: value });
          this.isCheckingLabel = false;
          if (res.data.found) {
            callback(new Error(__("Voice label already exists")));
          } else {
            callback();
          }
        }
      } catch (e) {
        console.log(e);
        this.isCheckingLabel = false;
      }
    };
    return {
      isCheckingName: false,
      isCheckingLabel: false,
      customSampleText: "",
      promptAudioFile: "",
      generatingAudio: false,
      promptAtAudioFileCreation: "",
      isSubmitting: false,
      isReceiving: false,
      ttsVoice: {},
      rules: {
        voice_name: [
          { required: true, trigger: "blur", message: __("Name is required") },
          { validator: validateVoiceName, trigger: "blur" }
        ],
        lang: [
          {
            required: true,
            trigger: "blur",
            message: __("Please select a language")
          }
        ],
        provider: [
          {
            required: true,
            trigger: "blur",
            message: __("Please select a provider")
          }
        ],
        gender: [
          {
            required: true,
            trigger: "blur",
            message: __("Please select a gender")
          }
        ],
        tts_label: [
          { required: true, trigger: "blur", message: __("Label is required") },
          { validator: validateVoiceLabel, trigger: "blur" }
        ]
      },
      isDisabled: false,
      isDifferentVoice: false
    };
  },
  computed: {
    ...mapState("ttsVoices", {
      isLoading: state => state.isLoading,
      languages: state => state.languages,
      providers: state => state.providers
    }),
    promptText() {
      return this.contentForm.sample_text;
    },
    promptContentChanged() {
      return (
        this.contentForm.sample_text !== this.promptAtAudioFileCreation ||
        this.isDifferentVoice
      );
    },
    isDisabledForEdit() {
      return (
        this.formAction === "edit" && this.contentForm.provider !== "lumenvox"
      );
    }
  },

  methods: {
    ...mapActions("ttsVoices", {
      createTTSVoice: "createTTSVoice",
      updateTTSVoice: "updateTTSVoice",
      deleteContentMethod: "deleteTTSVoice",
      undoDeleteContent: "undoDeleteTTsVoice"
    }),
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process =
            this.id === -1 ? this.createTTSVoice : this.updateTTSVoice;
          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("TTS Voice created Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("TTS Voice updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    generateAudio() {
      if (this.contentForm.sample_text) {
        this.generatingAudio = true;
        getAudioPrompt({
          prompt_text: this.contentForm.sample_text,
          tts_voice_id: this.contentForm.tts_voice_id
        })
          .then(({ data }) => {
            this.promptAudioFile = data.audio_url;
            this.promptAtAudioFileCreation = this.promptText;
            this.generatingAudio = false;
          })
          .catch(err => {
            this.generatingAudio = false;
            console.log(err);
          });
      } else {
        this.promptAudioFile = "";
      }
      this.isDifferentVoice = false;
    },
    updatePromptText(value) {
      this.$set(this.contentForm, "sample_text", value);
      if (!value) {
        this.$set(
          this.contentForm,
          "sample_text",
          this.contentFormInfo.sample_text
        );
      }
    },
    handleCancelBtn() {
      this.resetForm("contentForm");
      this.handleCancel();
    }
  },

  watch: {
    "contentForm.provider": function(oldVal, newVal) {
      if (
        this.formAction === "edit" &&
        oldVal === "lumenvox" &&
        oldVal !== newVal
      ) {
        this.isDisabled = false;
      }
    },
    formAction: {
      immediate: true,
      deep: true,
      handler: function(val) {
        if (val === "edit" && this.contentForm.provider !== "lumenvox") {
          this.isDisabled = true;
        } else {
          this.isDisabled = false;
        }
      }
    },

    "contentForm.tts_voice_id": function(oldVal, newVal) {
      if (this.formAction === "select" && oldVal !== newVal) {
        this.isDifferentVoice = true;
      } else {
        this.isDifferentVoice = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
