<script>
import List from "./pages/listVoices";
import CreateOrEdit from "./pages/createOrEditVoice";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "TTSVoicesIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },
  data() {
    return {
      selectedContent: this.initContent()
    };
  },
  computed: {
    ...mapState("ttsVoices", {
      contents: state => state.ttsVoices
    })
  },

  methods: {
    initContent() {
      return {
        tts_label: "",
        voice_name: "",
        provider: "",
        lang: "",
        gender: "",
        is_system_voice: false
      };
    }
  },
  mounted() {
    this.contentType = "voice";
    this.primaryKey = "tts_voice_id";
    this.scope = "sys";
  }
};
</script>
<style lang="scss" scoped></style>
