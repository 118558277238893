<template
  ><div style="width: 100%;" @click="handleClear">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('Voices')"
    />
    <el-dropdown
      class="actionDropDown"
      split-button
      type="primary"
      @click.stop="handleAdd"
      @command="handleCommand"
      v-show="this.canWrite()"
    >
      {{ __("Add Voice") }}
      <el-dropdown-menu slot="dropdown" id="add-voice-providers-dropdown">
        <el-dropdown-item command="google"
          ><i class="el-icon-refresh"></i
          >{{ __("Google Voices") }}</el-dropdown-item
        >
        <el-dropdown-item command="ibm"
          ><i class="el-icon-refresh"></i
          >{{ __("IBM Voices") }}</el-dropdown-item
        >
        <el-dropdown-item command="microsoft"
          ><i class="el-icon-refresh"></i
          >{{ __("Microsoft Voices") }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <div style="padding: 0 36px; margin-top: 136px;" v-loading="isLoading">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="contentTable"
            :data="ttsVoices"
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            @current-change="handleCurrentChange"
            highlight-current-row
            class="list-table"
            v-show="this.canRead()"
          >
            <el-table-column :label="__('Voice Label')">
              <template slot-scope="scope">
                <span>{{ scope.row.tts_label }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="__('Voice Name')">
              <template slot-scope="scope">
                <span>{{ scope.row.voice_name }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="__('Language Name')">
              <template slot-scope="scope">
                <span>{{ scope.row.lang_name }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="__('Gender')">
              <template slot-scope="scope">
                <span>{{ scope.row.gender }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="__('Provider')">
              <template slot-scope="scope">
                <span>{{ scope.row.provider.toUpperCase() }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import PageHeader from "@/components/PageHeader";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { EventBus } from "@/EventBus";

export default {
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  props: {
    msg: {
      required: false,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      search: "",
      filters: ["tts_voice_id"],
      sortByColumns: ["tts_label", "tts_voice_id"]
    };
  },
  computed: {
    ...mapState("ttsVoices", {
      ttsVoices: state => state.ttsVoices,
      isLoading: state => state.isLoading
    }),
    ...mapGetters("ttsVoices", {
      getProviderLabel: "getProviderLabel",
      getLanguageLabel: "getLanguageLabel"
    }),
    ttsVoicesToShow() {
      return this.ttsVoices;
    }
  },
  methods: {
    ...mapActions("ttsVoices", {
      contentAPI: "getTTSVoices",
      syncTTSVoices: "syncTTSVoices",
      deleteContentMethod: "deleteTTsVoice",
      undoDeleteContent: "undoDeleteTTsVoice"
    }),
    async syncProviderVoices(provider) {
      await this.syncTTSVoices(provider).then(data => {
        EventBus.$emit("list-changed", data.data);
      });
    },
    handleClear() {
      this.$refs.contentTable.setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },
    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    handleAdd() {
      this.$emit("open-create-modal");
      EventBus.$emit("open-create-modal");
    },
    async handleCommand(command) {
      if (
        command === "google" ||
        command === "ibm" ||
        command === "wellSaidLabs" ||
        command === "microsoft"
      ) {
        await this.syncProviderVoices(command);
      }
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null && val !== undefined) {
          this.$refs.contentTable &&
            this.$refs.contentTable.setCurrentRow(this.ttsVoices[val]);
        } else if (val === -1) {
          this.$refs.contentTable && this.$refs.contentTable.setCurrentRow();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$--color-node-hover-default: var(--theme-hover-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
.actionDropDown {
  position: absolute;
  top: 25px;
  right: 301px;
  border: none;
  color: white;
  background: $content-theme-color;
  border-radius: 6px;

  ::v-deep button {
    background: $content-theme-color;
    border-color: transparent;
    height: 44px;
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
    }
  }
}
</style>
